import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import qrSample from 'assets/images/apps/qr_sample.png';

const PasswordlessRegistration = ({ user }) => {
  const { t } = useTranslation();

  const onRegister = () => {
    if (!window.flutter_inappwebview) return;
    window.flutter_inappwebview.callHandler('onRegister', user.id, user.loginId);
  };

  return (
    <div className="row justify-content-center">
      <div className="col-12 col-lg-9 xs-m-l-r-0" style={{ marginTop: '10px', marginLeft: '30px' }}>
        <Row style={{ width: 500 }} className="m-w-100 m-0">
          <Col md={6} className="text-center">
            {/* <img src={qrSample} width="100%" alt={t('qr.sample')} /> */}
            <p className="text-color-sub">{t('profile.passwordless.registration.hint')}</p>
            <button
              className="btn btn-info btn-cons m-t-12 btn-block"
              style={{ borderRadius: '15px' }}
              type="button"
              onClick={onRegister}
            >
              {t('profile.passwordless.registration.button.setup')}
            </button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

PasswordlessRegistration.propTypes = {
  user: PropTypes.any
};

export default PasswordlessRegistration;
