import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'reactstrap';
import qrAppleStore from 'assets/images/apps/qr-apple-store.png';
import qrPlayStore from 'assets/images/apps/qr-play-store.png';
import appleEn from 'assets/images/apps/apple-store-badge.png';
import badgeGoogle from 'assets/images/apps/QRLogo_Android.png';
import t05En from 'assets/img/passwordless/EN/T05_EN.png';
import t05Jp from 'assets/img/passwordless/JP/T05_JP.png';
import t05Tw from 'assets/img/passwordless/TW/T05_TW.png';
import t06En from 'assets/img/passwordless/EN/T06_EN.png';
import t06Jp from 'assets/img/passwordless/JP/T06_JP.png';
import t06Tw from 'assets/img/passwordless/TW/T06_TW.png';
import t07En from 'assets/img/passwordless/EN/T07_EN.png';
import t07Jp from 'assets/img/passwordless/JP/T07_JP.png';
import t07Tw from 'assets/img/passwordless/TW/T07_TW.png';
import appleJp from 'assets/img/passwordless/JP/apple_store_JP.png';
import appleTw from 'assets/img/passwordless/TW/apple_store_TW.png';

const StepDownload = ({ handleNextStep }) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem('sysLanguage') || 'en_US';
  const imageSources = {
    en_US: appleEn,
    ja_JP: appleJp,
    zh_TW: appleTw
  };
  return (
    <>
      <Row>
        <Col md={12} className="text-color-sub">
          <p>{t('common.step.passwordless.step1.sub')}</p>
          <p>{t('common.step.passwordless.step1.sub1')}</p>
          <p>{t('common.step.passwordless.step1.sub2')}</p>
          <p>{t('common.step.passwordless.step1.sub3')}</p>
        </Col>
      </Row>
      <Row style={{ width: 500 }} className="m-w-100">
        <Col md={6} className="text-center">
          <img src={imageSources[lang]} height="50px" alt={t('mfa.download.apple')} style={{ width: '65%' }} />
          <img src={qrAppleStore} width="100%" alt={t('mfa.download.apple.qr')} />
        </Col>
        <Col md={6} className="text-center">
          <img src={badgeGoogle} style={{ borderRadius: '9px' }} height="50px" alt={t('mfa.download.google')} />
          <img src={qrPlayStore} width="100%" alt={t('mfa.download.google.qr')} />
        </Col>
      </Row>
      <Row style={{ alignItems: 'center' }}>
        <Col md="10" sm="12" className="text-color-sub text-left">
          {t('common.step.passwordless.step1.explainNext')}
        </Col>
      </Row>
      <Row style={{ alignItems: 'center' }}>
        <Button color="primary" className="m-l-5" onClick={handleNextStep}>
          {t('common.button.next')}
        </Button>
      </Row>
    </>
  );
};

const StepInstallation = ({ handleNextStep, handlePreviousStep, handleClose }) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem('sysLanguage') || 'en_US';
  const imageSources = {
    en_US: t05En,
    ja_JP: t05Jp,
    zh_TW: t05Tw
  };
  return (
    <>
      {/* <Row>
        <Col md={4} className="text-center">
          <p className="text-color-sub mt-4">{t('common.step.passwordless.step2.iphone')}</p>
          <img src={require('assets/img/example.png')} width="100%" alt={t('common.step.passwordless.step2.iphone')} />
        </Col>
        <Col md={4} className="text-center">
          <p className="text-color-sub mt-4">{t('common.step.passwordless.step2.android')}</p>
          <img src={require('assets/img/example.png')} width="100%" alt={t('common.step.passwordless.step2.android')} />
        </Col>
      </Row> */}
      <Row className="mt-4">
        <Col md={12}>
          <img src={imageSources[lang]} alt={t('common.step.passwordless.step2')} style={{ width: '77%' }} />
        </Col>
      </Row>
      <Row>
        <Button color="default" className="m-t-20" onClick={handleClose}>
          {t('common.button.close')}
        </Button>
        <Button color="default" className="m-t-20 m-l-5" onClick={handlePreviousStep}>
          {t('common.label.prev')}
        </Button>
        <Button color="primary" className="m-t-20 m-l-5" onClick={handleNextStep}>
          {t('common.button.next')}
        </Button>
      </Row>
    </>
  );
};

const StepRegistration = ({ handleNextStep, handlePreviousStep, handleClose }) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem('sysLanguage') || 'en_US';
  const imageSources = {
    en_US: t06En,
    ja_JP: t06Jp,
    zh_TW: t06Tw
  };
  return (
    <>
      <Row className="mt-4">
        <Col md={12}>
          <img src={imageSources[lang]} alt={t('common.step.passwordless.step3')} style={{ width: '77%' }} />
        </Col>
      </Row>
      <Row>
        <Button color="default" className="m-t-20" onClick={handleClose}>
          {t('common.button.close')}
        </Button>
        <Button color="default" className="m-t-20 m-l-5" onClick={handlePreviousStep}>
          {t('common.label.prev')}
        </Button>
        <Button color="primary" className="m-t-20 m-l-5" onClick={handleNextStep}>
          {t('common.button.next')}
        </Button>
      </Row>
    </>
  );
};

const StepAuthentication = ({ handlePreviousStep, handleClose }) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem('sysLanguage') || 'en_US';
  const imageSources = {
    en_US: t07En,
    ja_JP: t07Jp,
    zh_TW: t07Tw
  };
  return (
    <>
      <Row className="m-w-100 mt-4">
        <Col md={12}>
          <img src={imageSources[lang]} alt={t('common.step.passwordless.step4')} style={{ width: '77%' }} />
        </Col>
      </Row>
      <Row>
        <Button color="default" className="m-t-20" onClick={handlePreviousStep}>
          {t('common.label.prev')}
        </Button>
        <Button color="primary" className="m-t-20 m-l-5" onClick={handleClose}>
          {t('common.button.close')}
        </Button>
      </Row>
    </>
  );
};

const PasswordlessSetting = () => {
  const [step, setStep] = useState(1);
  const { t } = useTranslation();

  const handleNextStep = () => setStep(prev => prev + 1);
  const handlePreviousStep = () => setStep(prev => prev - 1);
  const handleClose = () => setStep(1);

  const renderStep = () => {
    switch (step) {
      case 2: {
        return (
          <StepInstallation
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
            handleClose={handleClose}
          />
        );
      }
      case 3: {
        return (
          <StepRegistration
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
            handleClose={handleClose}
          />
        );
      }
      case 4: {
        return <StepAuthentication handlePreviousStep={handlePreviousStep} handleClose={handleClose} />;
      }
      default: {
        return <StepDownload handleNextStep={handleNextStep} />;
      }
    }
  };
  return (
    <div className="row justify-content-center">
      <div className="col-12 col-lg-9 xs-m-l-r-0" style={{ marginTop: '10px', marginLeft: '30px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <h2>
            {t('common.step.passwordless', { header: t(`common.step.passwordless.step${step}`), step, total: 4 })}
          </h2>
        </div>
        <div>{renderStep()}</div>
      </div>
    </div>
  );
};

export default PasswordlessSetting;
