import 'assets/scss/theme/login.scss';
import './index.scss';
import fb from 'core/firebase';
import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { auth as authService } from 'services';
import Domain from './domain';
import { Loading } from 'element-react';
import { logger } from 'core/logger';
import validator from 'core/validator/customValidator';
import { FormFeedback, Row, Col } from 'reactstrap';
import { cookies, makeid } from 'core/utils';
import { Link } from 'react-router-dom';
import { regexpPassword } from 'core/constants';
import { BaseInput, BaseInputPassword, BaseSelect, Copyright, ModalBox } from 'components';
import i18next from 'i18n';
import PasswordlessLogin from './../Passwordless/index';
import { checkIsMobile } from 'core/helpers';

import {
  COOKIE_KEY_USER_TOKEN,
  STORAGE_KEY_APP_SYNC_SIGNED_IN,
  STORAGE_KEY_LAST_ACTIVE_TIME,
  COOKIE_KEY_APP_ATM_TOKEN,
  COOKIE_KEY_APP_ATM_TOKEN_EXP,
  COOKIE_KEY_REFRESH_TOKEN,
  MFA_METHODS,
  MODE_LOGIN,
  DOMAIN_TRANSCOSMOS,
  USER_ACCOUNT_LOGIN_BY_ID_MAX_LENGTH,
  EMAIL_MAX_LENGTH
} from 'core/constants';

function Login(props) {
  const {
    notification,
    history,
    updateFullLoading,
    fullloading,
    modeLogin,
    loginIdStore,
    setModeLogin,
    setLoginIdStore
  } = props;
  const { t } = useTranslation();
  let timer = null;
  const languages = [
    {
      value: 'en_US',
      label: 'English'
    },
    {
      value: 'ja_JP',
      label: '日本語'
    },
    {
      value: 'zh_TW',
      label: '繁體中文'
    }
  ];
  const loginModes = [
    {
      value: MODE_LOGIN.LOGIN_BY_LOGIN_ID,
      label: t('common.label.login.tci')
    },
    {
      value: MODE_LOGIN.LOGIN_BY_EMAIL,
      label: t('common.label.login.other')
    }
  ];
  const lang = localStorage.getItem('sysLanguage') || 'en_US';
  const langValue = languages.filter(a => a.value === lang)[0];
  const [sysLanguage, setSysLanguage] = useState(langValue);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [isDomainForm, setIsDomainForm] = useState(false);
  const [domainName, setDomainName] = useState('');
  const [countDown, setCountDown] = useState(0);
  const selectedMethod = localStorage.getItem('selectedMethod');
  const [loginIdMaxlength, setLoginIdMaxlength] = useState(EMAIL_MAX_LENGTH);
  const [env, setEnv] = useState('');

  const mountedRef = useRef(true);
  const passwordlessRef = useRef();

  useEffect(() => {
    if (modeLogin === MODE_LOGIN.LOGIN_BY_EMAIL) setLoginIdMaxlength(EMAIL_MAX_LENGTH);
    else if (MODE_LOGIN.LOGIN_BY_LOGIN_ID === modeLogin) setLoginIdMaxlength(USER_ACCOUNT_LOGIN_BY_ID_MAX_LENGTH);
  }, [modeLogin]);

  const checkCountDown = () => {
    if (MFA_METHODS.AUTHENTICATOR === selectedMethod) return;
    let ttl = 0;
    let seconds = 0;
    if (MFA_METHODS.SMS === selectedMethod) {
      ttl = localStorage.getItem('sendSmsOtpCodeTimestamp');
    } else if (MFA_METHODS.EMAIL === selectedMethod) {
      ttl = localStorage.getItem('sendEmailOtpCodeTimestamp');
    }
    if (ttl) {
      try {
        seconds = parseInt(ttl) - Math.floor(new Date().getTime() / 1000);
      } catch (error) {
        removeOtpCodeTimestamp(selectedMethod);
      }
    }
    setCountDown(seconds);
    if (seconds <= 0) removeOtpCodeTimestamp(selectedMethod);
  };

  const removeOtpCodeTimestamp = mfaMethod => {
    if (MFA_METHODS.SMS === mfaMethod) {
      localStorage.removeItem('sendSmsOtpCodeTimestamp');
    } else if (MFA_METHODS.EMAIL === mfaMethod) {
      localStorage.removeItem('sendEmailOtpCodeTimestamp');
    }
  };

  useEffect(() => {
    const token = cookies.getKey(COOKIE_KEY_USER_TOKEN);
    if (token) {
      history.push('/');
    }
    if (!modeLogin) {
      setModeLogin(MODE_LOGIN.LOGIN_BY_LOGIN_ID);
    }

    checkCountDown(email);

    const search = new URLSearchParams(window.location.search);
    const adDomain = search.get('ad');
    if (adDomain) {
      setIsDomainForm(true);
      setDomainName(adDomain);
    }

    return () => {
      mountedRef.current = false; // clean up function
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem('modeLogin', modeLogin);
  }, [modeLogin]);

  useEffect(() => {
    if (countDown > 0) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timer = setInterval(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [countDown]);

  useEffect(() => {
    const envName = process.env.REACT_APP_ENV_NAME || '';
    setEnv(envName);
  }, []);

  const validateInput = (email, pass) => {
    let result = true;
    if (!pass) {
      setErrorPassword('common.validator.isRequired');
      result = false;
    }
    const keyErrMsgEmail = validateEmail(email);
    result = !keyErrMsgEmail;
    if (!result) {
      toast.warning(t('common.message.invalidInputs'));
    }
    if (!regexpPassword.test(pass)) {
      toast.warning(t('validator.password.invalid'));
      result = false;
    }
    return result;
  };

  const validateEmail = email => {
    let keyErrMsg = '';
    if (!email) {
      keyErrMsg = 'common.validator.isRequired';
    } else {
      if (MODE_LOGIN.LOGIN_BY_EMAIL === modeLogin) {
        if (!validator.isCorrectEmail(email)) {
          keyErrMsg = 'common.validator.invalidEmail';
        }
      } else if (MODE_LOGIN.LOGIN_BY_LOGIN_ID === modeLogin) {
        // We need to +1 when checking due to @ character
        if (email.length + DOMAIN_TRANSCOSMOS.length + 1 > EMAIL_MAX_LENGTH) {
          keyErrMsg = 'common.validator.invalidLoginID';
        }
      }
    }
    setErrorEmail(keyErrMsg);
    return keyErrMsg;
  };

  const updateEmail = email => {
    validateEmail(email);
    setEmail(email);
    checkCountDown(email);
  };

  const updatePassword = password => {
    let err = '';
    if (!password) {
      err = 'common.validator.isRequired';
    }
    setErrorPassword(err);
    setPassword(password);
  };

  const setCookies = data => {
    const { userToken = '', refreshToken = '', atmToken = '', atmTokenExpiresAt = '' } = data;
    localStorage.setItem(STORAGE_KEY_APP_SYNC_SIGNED_IN, new Date().getTime());
    localStorage.setItem(STORAGE_KEY_LAST_ACTIVE_TIME, new Date().getTime());
    cookies.setKey(COOKIE_KEY_USER_TOKEN, userToken);
    cookies.setKey(COOKIE_KEY_REFRESH_TOKEN, refreshToken);
    cookies.setKey(COOKIE_KEY_APP_ATM_TOKEN, atmToken);
    cookies.setKey(COOKIE_KEY_APP_ATM_TOKEN_EXP, atmTokenExpiresAt);
  };

  const loginSuccess = async data => {
    const { code = '', mfa, mfaMethod } = data;
    logger.info('loginSuccess -> data: ', data);
    if (code === 'otp-required') {
      const ttl = Math.floor(new Date().getTime() / 1000);
      if (MFA_METHODS.EMAIL === mfaMethod) {
        localStorage.setItem('sendEmailOtpCodeTimestamp', ttl);
      } else if (MFA_METHODS.SMS === mfaMethod) {
        localStorage.setItem('sendSmsOtpCodeTimestamp', ttl);
      }
      localStorage.setItem(email, ttl);
      setLoginIdStore(email);
      localStorage.setItem('mfa', mfa);
      localStorage.setItem('mfaMethod', mfaMethod);
      localStorage.setItem('action', 'LOGIN');
      localStorage.removeItem('mfaRetry');
      localStorage.setItem('mfaLoginTimestamp', Math.round(Date.now() / 1000));
      history.push('/mfa');
    } else {
      localStorage.setItem('action', 'LOGIN');
      setCookies(data);
      history.push('/');
    }
  };

  const signIn = async e => {
    e.preventDefault();
    try {
      updateFullLoading(true);
      if (!validateInput(email, password)) return;
      const sendEmailOtpCodeTimestamp = localStorage.getItem('sendEmailOtpCodeTimestamp');
      const now = Math.floor(new Date().getTime() / 1000);
      if (loginIdStore === email && sendEmailOtpCodeTimestamp && parseInt(sendEmailOtpCodeTimestamp) + 30 > now) {
        toast.warning(t('mfa.message.too.many.email'));
        return;
      }
      setIsAuthenticating(true);
      await authenticate({ email, password: window.btoa(password) }).catch(error => {
        const response = error.response || {};
        if (response.status === 429) {
          const data = response.data || {};
          if (data.code === 'too-many-sms') {
            localStorage.setItem(email, data.ttl);
            checkCountDown(email);
          }
        }
        throw error;
      });
    } catch (err) {
      logger.error('signIn -> err', err);
      handleLoginError(err);
    } finally {
      setIsAuthenticating(false);
      updateFullLoading(false);
      if (window.flutter_inappwebview) {
        window.flutter_inappwebview.callHandler('loginSuccess', true);
      }
    }
  };

  const signInWithPasswordLess = async sessionQR => {
    console.log('session ===>: ', sessionQR);
    await authService
      .authenticate({ sessionQR })
      .then(res => {
        loginSuccess(res);
      })
      .catch(err => {
        logger.error('signIn -> err', err);
        handleLoginError(err);
      });
  };

  const authenticate = async ({ email, password, token }) => {
    let res;
    if (!token) {
      // email & password
      res = await authService.authenticate({ email, password });
    } else {
      // google
      res = await authService.authenticate({ token });
    }
    loginSuccess(res);
  };

  const signInWithGoogle = async e => {
    if (window.flutter_inappwebview) {
      updateFullLoading(true);
      const state = makeid(64);
      window.flutter_inappwebview.callHandler('loginWithGoogle', state);
      return;
    }

    if (window.location.search) {
      fb.signInWithGoogle(window.location.href);
    } else {
      fb.signInWithGoogle();
    }
  };

  const handleLoginError = (err, googleEmail = null) => {
    const { response } = err;
    const { data = {} } = response || {};
    const code = data.code;
    const errorType = data.errorType;
    logger.error('Login -> code', code);
    let warningMessage = '';
    switch (code) {
      case 'auth/user-not-found':
      case 'user-not-found':
        warningMessage = t('common.message.user.notFound');
        break;
      case 'account-is-locked':
        warningMessage = t('common.message.user.locked');
        break;
      case 'account-locked-by-wrong-email-password': {
        warningMessage =
          errorType === 'MFA'
            ? t('login.message.wrongPassword.accountLocked-mfa')
            : t('login.message.wrongPassword.accountLocked-nonMfa');
        break;
      }
      case 'domain-not-found':
        warningMessage = t('common.message.domain.notFound');
        break;
      case 'domain-inactive':
        warningMessage = t('common.message.domain.inactive');
        break;
      case 'method-not-allow':
        warningMessage = t('common.message.provider.invalid');
        break;
      case 'invalid-email-or-password': {
        warningMessage =
          modeLogin === 'login'
            ? t('login.message.emailOrPassword.incorrect')
            : t('login.message.idOrPassword.incorrect');
        break;
      }
      case 'generate-otp-error': {
        warningMessage = t('mfa.message.generate.otp.error');
        break;
      }
      case 'invalid-emp-status':
      case 'invalid-commit-date':
      case 'invalid-retire-date':
        warningMessage =
          modeLogin === 'login' ? t('login.message.disable.invalid.attr') : t('login.message.disable.invalid.attr.id');
        break;
      case 'too-many-sms':
        warningMessage = t('mfa.message.too.many.sms');
        break;
      case 'country-not-support-sms':
        warningMessage = t('common.message.mobile.not.support.country');
        break;
      case 'mfaMethod-is-not-existed':
        warningMessage = t('mfa.method.not.existed');
        break;
      case 'missing-relay-state':
        warningMessage = t('saml.missing.relay.state');
        break;
      case 'invalid-relay-state':
        warningMessage = t('saml.invalid.relay.state');
        break;
      case 'nameid-not-match':
        warningMessage = t('saml.nameid.not.match');
        break;
      case 'invalid-saml-response':
        warningMessage = t('saml.invalid.saml.response');
        break;
      case 'max-25-connection':
        warningMessage = t('Common.Error.TooManySessions');
        break;
      case 'contact-email-is-required-in-policy':
        warningMessage = t('Common.Message.ContactEmail.Required');
        break;
      case 'mfa-is-required-in-policy':
        warningMessage = t('Common.Message.MFA.Required');
        break;
      default:
        toast.error(t('common.message.error'));
    }
    if (warningMessage) {
      toast.warning(warningMessage);
      if (code && ['account-is-locked', 'account-locked-by-wrong-email-password'].includes(code)) {
        setTimeout(() => {
          localStorage.setItem('requiredLock', true);
          setLoginIdStore(googleEmail ? googleEmail : email);
          history.push('/unlock-request');
        }, 2000);
      }
    }
  };

  const changeLanguage = res => {
    const language = res.value;
    localStorage.setItem('sysLanguage', language);
    i18n.changeLanguage(language);
    const langValue = languages.filter(a => a.value === language)[0];
    setSysLanguage(langValue);
  };

  const changeModeLogin = value => {
    gotoLogin();
    setModeLogin(value);
  };

  const gotoLogin = () => {
    history.push('/tci');
    setDomainName('');
    setIsDomainForm(false);
    setErrorPassword('');
    setErrorEmail('');
    setEmail('');
    setPassword('');
    setCountDown(0);
  };

  const gotoDomain = () => {
    setIsDomainForm(true);
  };

  const gotoForgot = e => {
    e.preventDefault();
    const keyErrMsg = validateEmail(email);
    if (keyErrMsg) {
      const warningMessage = t(keyErrMsg, {
        label: modeLogin === 'login' ? t('login.login_id') : t('profile.loginId.label')
      });
      toast.warning(warningMessage);
      return;
    }
    history.push('/forgot-password');
    setLoginIdStore(email);
  };

  return (
    <div className="login-wrapper login-page">
      <div className="row no-gutters">
        <div className="d-none d-sm-none d-md-block col-md-5 col-lg-6 col-xl-7">
          <div className="background" style={{ backgroundImage: 'url(' + require('assets/img/login/bg.jpg') + ')' }}>
            <div className="bg-caption pull-bottom sm-pull-bottom text-white p-l-20 p-b-10">
              <h2 className="semi-bold text-white">{t('login.slogan')}</h2>
              <Copyright className="small" />
            </div>
            <div className="backdrop" />
          </div>
        </div>
        <div className="col-sm-12 col-md-7 col-lg-6 col-xl-5">
          <div className="login-container bg-white">
            <div
              className={`${
                checkIsMobile() ? 'login-mobile' : ''
              } p-l-50 m-l-20 p-r-50 m-r-20 p-t-40 m-t-12 sm-p-l-15 sm-p-r-15 sm-p-t-40 m-p-l-0 m-p-r-0 content`}
            >
              <div className="row">
                <div className="col-sx-12 col-6 text-sx-center d-flex align-items-center">
                  <img
                    style={{ maxHeight: '60px', marginTop: '7px' }}
                    src={require('assets/img/logo_2x.png')}
                    alt="SSO Portal"
                  />
                  <span className="environment-name-login">{env}</span>
                </div>
                <div className="col-sx-12 col-6 text-right text-sx-center">
                  <img
                    style={{ maxHeight: '70px' }}
                    className="img-fluid"
                    src={require('assets/img/TCI-PF-certificate.png')}
                    alt="TCI-PF System Cert t-passport"
                  />
                </div>
              </div>
              <p className="m-t-12">{t('login.signin.title')}</p>
              <div className="flex-grow-1 d-flex justify-content-end">
                {!isDomainForm && (
                  <div style={{ width: '100%' }}>
                    <BaseSelect
                      value={modeLogin}
                      options={loginModes}
                      onChange={changeModeLogin}
                      multiple={false}
                      sortable={false}
                      allowSort={false}
                    />
                  </div>
                )}
              </div>
              {modeLogin === MODE_LOGIN.LOGIN_BY_LOGIN_ID && (
                <Row>
                  <Col sm="12">
                    {!isDomainForm && (
                      <form
                        method="post"
                        name="login-form"
                        className="m-t-12"
                        action="#"
                        autoComplete="off"
                        onSubmit={signIn}
                      >
                        <div className="form-group form-group-default">
                          <label>{t('profile.loginId.label')}</label>
                          <BaseInput
                            type="input"
                            className="form-control"
                            name="email"
                            value={email}
                            maxLength={loginIdMaxlength}
                            preventAtSpecial={true}
                            onChange={updateEmail}
                            placeholder={t('placeholder.input.Enter', {
                              field: t('profile.loginId.label')
                            })}
                          />
                        </div>
                        <FormFeedback className="d-block">
                          {errorEmail
                            ? t(errorEmail, {
                                label: t('profile.loginId.label')
                              })
                            : ''}
                        </FormFeedback>
                        <div className="form-group form-group-default">
                          <label>{t('common.password.label')}</label>
                          <BaseInputPassword
                            showStrength={false}
                            showEyeIcon={false}
                            className="form-control"
                            name="password"
                            value={password}
                            onChange={updatePassword}
                            placeholder={t('placeholder.input.Enter', { field: t('common.password.label') })}
                          />
                        </div>
                        <FormFeedback className="d-block">
                          {errorPassword ? t(errorPassword, { label: t('common.password.label') }) : ''}
                        </FormFeedback>
                        {!checkIsMobile() && (
                          <div className="d-flex align-items-center">
                            <div className="m-r-15">
                              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                              <a style={{ fontSize: '0.7rem' }} href="/forgot-password" onClick={gotoForgot}>
                                {t('common.label.forgotPassword')}
                              </a>
                            </div>
                            <div className="flex-grow-1 d-flex justify-content-end">
                              <div style={{ maxWidth: '162px', width: '100%' }}>
                                <Select
                                  value={sysLanguage}
                                  onChange={changeLanguage}
                                  options={languages}
                                  classNamePrefix="react-select"
                                  menuPlacement="top"
                                  noOptionsMessage={() => i18next.t('common.label.no.option')}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        <button
                          className={`${checkIsMobile() ? 'm-t-30' : 'm-t-10'} btn btn-primary btn-cons btn-block`}
                          type="submit"
                          disabled={isAuthenticating || countDown > 0}
                        >
                          {countDown > 0 ? (
                            <span>{t('login.signin.button') + ' ' + countDown}</span>
                          ) : !isAuthenticating ? (
                            <span>{t('login.signin.button')}</span>
                          ) : (
                            <span>
                              {t('login.signin.button.waiting')}...
                              <i className="fa fa-spinner fa-spin	fa-lg" />
                            </span>
                          )}
                        </button>
                        {!checkIsMobile() && (
                          <button
                            className="btn btn-info btn-cons m-t-12 btn-block"
                            type="button"
                            onClick={() => {
                              if (passwordlessRef.current && passwordlessRef.current.handleToggle) {
                                passwordlessRef.current.handleToggle();
                              }
                            }}
                          >
                            {t('login.passwordless')}
                          </button>
                        )}
                      </form>
                    )}
                  </Col>
                </Row>
              )}
              {modeLogin === MODE_LOGIN.LOGIN_BY_EMAIL && (
                <Row>
                  <Col sm="12">
                    {!isDomainForm && (
                      <form
                        method="post"
                        name="login-form"
                        className="m-t-12"
                        action="#"
                        autoComplete="off"
                        onSubmit={signIn}
                      >
                        <div className="form-group form-group-default">
                          <label>{t('login.login_id')}</label>
                          <BaseInput
                            type="input"
                            className="form-control"
                            name="email"
                            value={email}
                            maxLength={loginIdMaxlength}
                            preventAtSpecial={false}
                            onChange={updateEmail}
                            placeholder={t('placeholder.input.Enter', {
                              field: t('login.login_id')
                            })}
                          />
                        </div>
                        <FormFeedback className="d-block">
                          {errorEmail
                            ? t(errorEmail, {
                                label: t('login.login_id')
                              })
                            : ''}
                        </FormFeedback>
                        <div className="form-group form-group-default">
                          <label>{t('common.password.label')}</label>
                          <BaseInputPassword
                            showStrength={false}
                            showEyeIcon={false}
                            className="form-control"
                            name="password"
                            value={password}
                            onChange={updatePassword}
                            placeholder={t('placeholder.input.Enter', { field: t('common.password.label') })}
                          />
                        </div>
                        <FormFeedback className="d-block">
                          {errorPassword ? t(errorPassword, { label: t('common.password.label') }) : ''}
                        </FormFeedback>
                        {!checkIsMobile() && (
                          <div className="d-flex align-items-center">
                            <div className="m-r-15">
                              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                              <a style={{ fontSize: '0.7rem' }} href="/forgot-password" onClick={gotoForgot}>
                                {t('common.label.forgotPassword')}
                              </a>
                            </div>

                            <div className="flex-grow-1 d-flex justify-content-end">
                              <div style={{ maxWidth: '162px', width: '100%' }}>
                                <Select
                                  value={sysLanguage}
                                  onChange={changeLanguage}
                                  options={languages}
                                  classNamePrefix="react-select"
                                  menuPlacement="top"
                                  noOptionsMessage={() => i18next.t('common.label.no.option')}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        <button
                          className={`${checkIsMobile() ? 'm-t-30' : 'm-t-12'} btn btn-primary btn-cons btn-block`}
                          type="submit"
                          disabled={isAuthenticating || countDown > 0}
                        >
                          {countDown > 0 ? (
                            <span>{t('login.signin.button') + ' ' + countDown}</span>
                          ) : !isAuthenticating ? (
                            <span>{t('login.signin.button')}</span>
                          ) : (
                            <span>
                              {t('login.signin.button.waiting')}...
                              <i className="fa fa-spinner fa-spin	fa-lg" />
                            </span>
                          )}
                        </button>

                        <div>
                          {!checkIsMobile() && (
                            <button
                              onClick={() => {
                                if (passwordlessRef.current && passwordlessRef.current.handleToggle) {
                                  passwordlessRef.current.handleToggle();
                                }
                              }}
                              className="btn btn-info btn-cons m-t-12 btn-block"
                              type="button"
                            >
                              {t('login.passwordless')}
                            </button>
                          )}
                          <button
                            className="btn btn-info btn-cons m-t-12 btn-block"
                            type="button"
                            onClick={signInWithGoogle}
                          >
                            {t('login.signin.button.google')}
                          </button>
                          <button className="btn btn-info btn-cons m-t-12 btn-block" type="button" onClick={gotoDomain}>
                            {t('login.signin.button.ldap')}
                          </button>
                        </div>
                      </form>
                    )}
                  </Col>
                </Row>
              )}
              {isDomainForm && (
                <Domain
                  gotoToLogin={gotoLogin}
                  handleLoginError={handleLoginError}
                  updateFullLoading={updateFullLoading}
                  loginSuccess={loginSuccess}
                  history={history}
                  domainName={domainName}
                />
              )}
            </div>
            {!checkIsMobile() && (
              <div className="sm-p-l-15 sm-p-r-15 terms_of_use-href m-b-20" style={{ marginLeft: '70px' }} align="left">
                <Link to={'terms-of-use'} target="_blank" title={t('termsOfUse.title')}>
                  <span>{t('termsOfUse.title')}</span>
                </Link>
              </div>
            )}
            <div className="copyright-xs">
              <Copyright className="small" />
            </div>
          </div>
        </div>
      </div>
      {notification.showModal && <ModalBox />}
      {fullloading && <Loading fullscreen={true} />}
      <PasswordlessLogin ref={passwordlessRef} signIn={signInWithPasswordLess} />
    </div>
  );
}

export default Login;
